import React from "react";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import brand from "../images/flyt_logo.png";
import home from "../Navicons/home.png";
// import discord from "../Navicons/discord.png";
import opensea from "../Navicons/opensea.png";
import whitepaper from "../Navicons/whitepaper.png";
import twite from "../Navicons/twitter.png";
import navIcon from "../Navicons/navIcon.png";
import orb from "../Navicons/orb.png";
import "./navbar.css";
import Player from "./Player";

const Navigation = () => {
  return (
    <Navbar expand="lg"  sticky="top" className="navbar  ">
      <Container fluid>
        <Navbar.Brand href="/home">
          <img src={brand} alt="" className="brand" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" id="hhhh">
          <img src={navIcon} alt="" id="navIcon"/>
        </Navbar.Toggle>
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end  "
          // style={{ height: "70px" }}
        >
          <Nav
            className=" flex-column align-item-center "
            style={{ maxHeight: "100px" }}
           
          >
            <div className="nav flex-column">
              {/* {
                showTopBtn && ()
              } */}
              <Button size=""  className="land">
                <Nav.Link href="/home" className="link ">
                  <img src={home} alt="" />
                  <h6>Home</h6>
                </Nav.Link>
              </Button>
              <Button variant="light" size="lg" className="  land">
                <Nav.Link href="/theorb" className="link ">
                  <img src={orb} alt="" />
                  <h6>TheOrb</h6>
                </Nav.Link>
              </Button>
              <Button variant="light" size="lg" className=" land">
                <Nav.Link href="https://flyt.gitbook.io/flyt/" className="link">
                  <img src={whitepaper} alt="" />
                  <h6>Whitepaper</h6>
                </Nav.Link>
              </Button>
              <Button variant="light" size="lg" className=" land">
                <Nav.Link href="https://twitter.com/flytnft" className="link">
                  <img src={twite} alt="" />
                  <h6>Twitter</h6>
                </Nav.Link>
              </Button>
              <Button variant="light" size="lg" className=" land">
                <Nav.Link href="https://opensea.io/collection/origins-of-flyt" className="link">
                  <img src={opensea} alt="" />
                  <h6>OpenSea</h6>
                </Nav.Link>
              </Button>
              
              <Button className="player land"> <Player/></Button>

              
            </div>
          </Nav>
          
        </Navbar.Collapse>
        
      </Container>
    </Navbar>
  );
};

export default Navigation;