// import { Parallax, ParallaxLayer } from "@react-spring/parallax";


import Navigation from "./Component/Navigation";
import Home from './Component/Home';
import Player from "./Component/Player"; 
import React ,{useState,useEffect} from 'react'
import LoadingPage from "./Component/LoadingPage";
import Theorb from "./Component/Theorb";

import {BrowserRouter,Routes,Route} from 'react-router-dom';


function App() {
  const [loading, setLoading]= useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(()=>{
      setLoading(false);
    },2000);

  }, [])
  
  return (
    <div>
      {/* <Navigation/> */}

      <BrowserRouter>
      
          <Routes>
            {/* <Route element={<Navigation/>}/> */}
            <Route exact path='/' element={<Home/>} />
            <Route path='/home' element={<Home/>} />
            <Route path='/theorb' element={<Theorb/>} />
          </Routes>
      </BrowserRouter> 
    </div>
       
  );
}

export default App;
