import React from "react";
import { useRef, useState,useEffect} from "react";
import ON from "../Navicons/musicOn.png"
import OFF from "../Navicons/musicOff.png"
import "./player.css";
import sound from '../assets/newsound.m4a'
// import { songsdata } from './Songdata.js';
// import {BsFillSkipStartCircleFill, BsFillSkipEndCircleFill} from 'react-icons/bs';


const Player = () => {

    const [value, setValue] = useState(false);
    
    const clickRef = useRef();
    
    const btnhandler = () => {
      setValue(!value);
    }

    useEffect(() =>{
      

        if(value){
          
          clickRef.current.play();
        
        }
        else {
          clickRef.current.pause();
          
          
        }
        
    }, [value]);


      return (
    <div>
      <audio src={sound}  ref={clickRef} loop ></audio>
      {
        value? <img src={ON} alt="on" onClick={btnhandler} /> : <img src={OFF} alt="off" onClick={btnhandler} />
      }
    </div>
  );
 
};

export default Player;