import React, { useState } from "react";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import "./Home.css";
import Navigation from "./Navigation";

const Home = () => {
  

  return (
    <div className="home" >
      <Navigation/>
      <Parallax pages={4} style={{ top: "0", left: "0" }} class="animation">
        <ParallaxLayer offset={0} speed={0}>
          <div class="animation_layer parallax" id="background"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={0.25}>
          <div class="animation_layer parallax" id="Top_mount_side"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={0.1}>
          <div class="animation_layer parallax" id="Mountain"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={0}>
          <div class="animation_layer parallax" id="Right_Side"></div>
        </ParallaxLayer>

        <ParallaxLayer offset={1} speed={0}>
          <div class="animation_layer parallax" id="secondpageback"></div>
        </ParallaxLayer>
      
        <ParallaxLayer offset={1} speed={0.1}>
          <div class="animation_layer parallax" id="paper"></div>
        </ParallaxLayer>

        <ParallaxLayer offset={2} speed={0}>
          <div class="animation_layer parallax" id="thirdpagebackground"></div>
        </ParallaxLayer>

        <ParallaxLayer offset={2} speed={0.2}>
          <div class="animation_layer parallax" id="rightgirl"></div>
        </ParallaxLayer>

        <ParallaxLayer offset={3} speed={0}>
          <div class="animation_layer parallax" id="fourthpageback"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={3} speed={0.2}>
          <div class="animation_layer parallax" id="fly"></div>
        </ParallaxLayer>

        {/* <ParallaxLayer offset={3} speed={0}>
          <div class="animation_layer parallax" id="kbackground"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={3} speed={0}>
          <div class="animation_layer parallax" id="k1stback"></div>
        </ParallaxLayer> 
        <ParallaxLayer offset={3} speed={-0.05}>
          <div class="animation_layer parallax" id="k2ndback"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={3} speed={0.1}>
          <div class="animation_layer parallax" id="k3ndback"></div>
        </ParallaxLayer> 
        <ParallaxLayer offset={3} speed={0}>
          <div class="animation_layer parallax" id="k4ndback"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={3} speed={0}>
          <div class="animation_layer parallax" id="k5ndback"></div>
        </ParallaxLayer> 
        <ParallaxLayer offset={3} speed={0.2}>
          <div class="animation_layer parallax" id="kfly"></div>
        </ParallaxLayer>  */}

        <ParallaxLayer speed={-1.25}>
          <div class="animation_layer parallax" id="firstpagemooon"></div>
        </ParallaxLayer>
        {/* <ParallaxLayer speed={-0.99}>
        <div sticky = "mid" class="scroll">
              Scroll
      </div>
        </ParallaxLayer> */}
        
      </Parallax>
      
    </div>
  );
};

export default Home;
