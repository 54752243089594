import React from 'react'
import './theorb.css'
import bgVideo from '../video/theorb.mp4'
import bgVideoMobile from '../video/mobvideo.mp4'
import Navigation from './Navigation'
import mob from "../video/mob.jpg"
import pc from "../video/pc.jpg"

function Theorb() {
  return (
    <div>
      <div className='main-container'>
          
          <video autoPlay loop muted allowFullScreen allowfullscreen='true' playsInLine controls={false}>
            {/* <source src={bgVideo} type='video/mp4'></source> */}
            <source src={bgVideo} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
            {/* <source src={bgVideo} type='video/webm; codecs="vp9, vorbis"' /> */}
            <img src={pc} title="Your browser does not support the <video> tag" alt='img'/>
          </video>
          <div className='NAV'>
            <Navigation/>
          </div>
          
      </div>

      <div className='main-container-mob'>
              
      <video autoPlay loop muted allowFullScreen allowfullscreen='true' playsInLine controls={false}>
            {/* <source src={bgVideo} type='video/mp4'></source> */}
            <source src={bgVideoMobile} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
            {/* <source src={bgVideoMobile} type='video/webm; codecs="vp8, vorbis"' /> */}
            <img src={mob} title="Your browser does not support the <video> tag" alt='img'/>
          </video>
          <div className='NAV'>
            <Navigation/>
          </div>
      </div>


</div>

    
  )
}

export default Theorb